@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.text-slider {

    .slick-prev:before, .slick-next:before {
        color: $black;
    }

    .slick-arrow:hover:before {
        color: $white;
    }

    .slick-prev {
        left: 15px;
    }

    .slick-next {
        right: 15px;
    }

    ul.slick-dots {
        bottom: -50px;

        li {
            width: 8px;
            height: 8px;

            button {
                border: 1px solid $bronze;
                border-radius: 100%;
                width: 8px;
                height: 8px;
                padding: 0;

                &:before {
                    width: 100%;
                    height: 100%;
                    content: "";
                }
            }

            &.slick-active {
                button {
                    background-color: $bronze;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) {
        .slick-slider {
            position: static;
        }
    }
}
